import * as React from "react"
import * as styles from "../components/WorkCard.module.css"

import { useState } from "react"

const WorkCard = ({ res }) => {
    const [visible, setVisible] = useState(false);
    
    return (
       <>
            <button onClick={() => setVisible(!visible)} className={styles.project}>{res.title}</button>
            <div className={` ${styles.project__content} ${visible ? styles.visible : styles.project__content}`}>
                <p  className={styles.content__assignment}>{res.schoolvak}</p>
                <p className={styles.content__description}>{res.description}</p>
                <a   className={styles.content__link} href={res.url}>View
                    project</a>
            </div>
               
        </>
     
    )};

export default WorkCard;
