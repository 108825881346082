import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import WorkCard from '../components/WorkCard';
import * as styles from "../components/pages/lijstje.module.css"



  
const Corner = () => {
     /* const [visible, setVisible] = useState(true);
      console.log(visible);*/

      
            return(
          <StaticQuery
          query={graphql`
            query MyQueryWorks {
          allStrapiWork (sort: {fields: createdAt order: DESC}){
            nodes {
              schoolvak
              strapi_id
              title
              url
              description
            }
          }
        }

      `}

  render={data => (
        <Layout>
  
          <div className={styles.wrapper}>
            <div className={styles.description}>
                <h1>Chronologisch lijstje</h1>
                <p  className={styles.intro}>Drie jaar Devine, das heftig 🥵 maaarrr ook een geweldig zalige tijd met veel projecten. Een chronologisch lijstje helpt me af en toe herinneren dat ik best wel fier mag zijn op de weg die ik heb afgelegd. Als je klikt op een project ontdek je er alles over.
                </p>
                <Link className={styles.link} to="/projects">Terug naar projecten</Link>
              </div>
              <div className={styles.works}>
                {data.allStrapiWork.nodes.map((res, i) => (
                  <WorkCard key={i} res={res} />
                )
                )}
                </div>
              </div>

          </Layout>
  )}
  />
              )
            
            }


export const Head = () => <Seo title="Lijstje" />

export default Corner;
